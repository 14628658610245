////////////////////////////////////////
// Modals
////////////////////////////////////////

@import "bootstrap/modals";

.modal-header {
    background-color: $blue-grey-900;
    color: $white;

    border-radius: ($border-radius-base - 1) ($border-radius-base - 1) 0 0;

    .close {
        margin-top: 0;
        line-height: 34px;
        text-shadow: none;
    }

    .close {
        margin-top: 0;
        line-height: 34px;
        color: $blue-grey-300;
        font-weight: 300;
        text-shadow: none;

        &:hover,
        &:focus {
            color: $blue-grey-50;
        }
    }
}

.modal-title {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
}

.modal-content {
    border-radius: $border-radius-base;

    -webkit-box-shadow: 0 10px 20px rgba(0,0,0,0.4);
       -moz-box-shadow: 0 10px 20px rgba(0,0,0,0.4);
            box-shadow: 0 10px 20px rgba(0,0,0,0.4);
}

.modal-footer {
    text-align: center;
    background: $modal-footer-bg;
    padding: $modal-footer-padding;

    border-radius: 0 0 ($border-radius-base - 1) ($border-radius-base - 1);
}

////////// Font awesome //////////

.modal-title {
    > .fa, > .far, > .fal, > .fas, > .fab {
        margin-right: 10px;
        text-align: center;

        font-size: 14px;
        line-height: 34px;
        vertical-align: middle;

        display: inline-block;
        float: left;
    }
}

////////// Modal heading themes //////////

.modal-title {
    &.success > i {
        color: $light-green-500;
    }

    &.info > i {
        color: $light-blue-500;
    }

    &.warning > i {
        color: $amber-500;
    }

    &.danger > i {
        color: $red-500;
    }
}

////////// Modal download //////////

.modal-download {
    text-align: center;
}

.modal-download .modal-header .close {
    margin-left: -12px;
}

.modal-download {
    .fa, .far, .fal, .fas, .fab {
        margin-top: -20px;
        margin-bottom: 10px;
        font-size: 64px;
        display: block;
    }
}

.modal-download .modal-body span {
    font-size: 14px;
    line-height: 18px;
}

.modal-download .modal-body .filename {
    font-weight: bold;
}

.modal-download .progress {
    margin-top: 20px;
    margin-bottom: 0;
}

.modal-download .btn {
    width: 100%;
    margin-top: 20px;
}
