//////////////////////////////
// Labels
//////////////////////////////

@import "bootstrap/labels";

.label {
    padding: 0.2em .6em .2em;
    border: 2px solid;

    + .label {
        margin-left: 5px;
    }
}
