//////////////////////////////
// Tooltip
//////////////////////////////

@import "bootstrap/tooltip";

////////// Helpers //////////

.tooltip-break-word .tooltip-inner {
    word-wrap: break-word;
}
