//////////////////////////////
// Callouts
//////////////////////////////

@mixin callout-variant($border-color, $background-color, $heading-color, $color) {
    border-color: $border-color;
    background-color: $background-color;
    color: $color;

    > h4 {
        color: $heading-color;
    }
}
