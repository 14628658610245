//////////////////////////////
// Buttons
//////////////////////////////

@import "bootstrap/buttons";

.btn {
    @include box-shadow(0 5px 5px -5px rgba(0,0,0,.3));

    &,
    &:active,
    &.active {
        &:focus,
        &.focus {
            outline: none;
        }
    }

    &:active,
    &.active {
        @include box-shadow(none);
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        @include button-variant($btn-disabled-color, $btn-disabled-bg, $btn-disabled-border);
        @include opacity(.65);
        @include box-shadow(none);
    }
}

.btn-link {
    @include box-shadow(none);
}

////////// Sizes //////////

.btn {
    line-height: ($input-height-base - ($padding-base-vertical * 2)) - 2;
}

.btn-lg,
.btn-sm {
    font-size: $font-size-base;
}

.btn-lg {
    padding-top: $padding-large-vertical + floor(($font-size-large - $font-size-base) / 2);
    padding-bottom: $padding-large-vertical + floor(($font-size-large - $font-size-base) / 2);
}

.btn-sm {
    padding-top: $padding-small-vertical - floor(($font-size-base - $font-size-small) / 2);
    padding-bottom: $padding-small-vertical - floor(($font-size-base - $font-size-small) / 2);
}

////////// Variants //////////

.btn-text {
    padding: 0;
    border: none;
    font-size: inherit;
    line-height: inherit;
    display: inline;
}

.btn-contrast {
    @include button-variant($btn-contrast-color, $btn-contrast-bg, $btn-contrast-border);
}

.btn-inverse {
    @include button-variant($btn-inverse-color, $btn-inverse-bg, $btn-inverse-border);
}

.btn-sidebar {
    @include button-variant($text-color, $btn-contrast-bg, $btn-contrast-border);

    padding-right: 20px; // Make some space for the sort icon.
    text-align: left;
    position: relative;

    box-shadow: none;

    .fa-sort,
    .eagle-more {
        font-size: 12px;
        display: block;
        position: absolute;
        top: 17px;
        right: 5px;
    }

    .btn-title > small {
        color: $text-muted;
    }
}

.btn-title {
    width: 100%;
    display: inline-block;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    vertical-align: middle;

    > small {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &.btn-subtitle {
        line-height: 1;
    }
}

////////// Icons //////////

.btn {
    .avatar {
        margin-top: 4px;
    }

    .fa.pull-right {
        line-height: inherit;
    }
}

.btn-sidebar {
    .fa.pull-right {
        font-size: 12px;
    }
}

////////// Overflow style //////////

.btn-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
}

////////// Toggles / Controls //////////

// @todo: Duplicate of list-group-item btn
.toggle,
.controls-toggle {
    @include button-variant($text-color, $white, transparent);
    @include box-shadow(none);

    width: 40px; height: 40px;
    padding: 0;
    font-size: 14px;
    line-height: 40px;
    position: relative;

    > i {
        margin-left: 0;
        margin-right: 0;
    }

    &:hover,
    &:focus {
        outline: 0;
    }

    &:active,
    &.active {
        outline: 0;
    }
}

//////////

// TODO: Remove, this is replaced with hero actions.
.btn-add {
    width: 40px; height: 40px;
    padding: 0; margin: 0;

    line-height: 40px;

    border-radius: 20px;
    @include box-shadow(0 10px 10px -8px rgba(0,0,0,.5));
}

.toggle-danger {
    color: $red-500;
}

//////////

@media (min-width: 992px) {
    .panel-toggle .droppanel-open .controls-toggle {
        display: none;
    }
}
