//////////////////////////////
// Country flags
//////////////////////////////

$country-flags-path: "https://o.auroraobjects.eu/cdn02/foundation/country-flags/";

@mixin country-flag($countryCode, $country) {
    .flag-#{$countryCode}::before {
        background-image: url("#{$country-flags-path}#{$country}.svg");
    }
}

@mixin country-flag-background {
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
}

.flag {
    display: inline-block;
    line-height: 1;

    &::before {
        content: '\00a0';
        width: 1em; height: 1em;

        @include country-flag-background();

        display: inline-block;
        vertical-align: middle;
    }
}

.flag-2x::before {
    width: 2em; height: 2em;
    line-height: 2; // Font-awesome doesn't need this, not sure whats going on here.
}

@include country-flag('be', 'belgium');
@include country-flag('es', 'spain');
@include country-flag('nl', 'netherlands');
@include country-flag('us', 'united-states-of-america');
