////////////////////////////////////////
// Grid
////////////////////////////////////////

@import "bootstrap/grid";

////////// Widths //////////

.container {
  @media (min-width: $screen-xl-min) {
    width: $container-xl;
  }
}

////////// Fixed widths //////////

.container-sm-fixed {
    @media (min-width: $screen-sm-min + $sidebar-width) {
      width: $container-sm;
    }
}

.container-md-fixed {
    @media (min-width: $screen-md-min + $sidebar-width) {
      width: $container-md;
    }
}

.container-lg-fixed {
    @media (min-width: $screen-lg-min + $sidebar-width) {
      width: $container-lg;
    }
}

.container-xl-fixed {
    @media (min-width: $screen-xl-min + $sidebar-width) {
      width: $container-xl;
    }
}

// Row
//
// Rows contain and clear the floats of your columns.

.row.row-grid-0 {
    @include make-row(0);
    @include make-grid-columns-adjustable(0);
}

.row.row-grid-xs {
    @include make-row($grid-gutter-width / 4);
    @include make-grid-columns-adjustable($grid-gutter-width / 4);
}

.row.row-grid-sm {
    @include make-row($grid-gutter-width / 2);
    @include make-grid-columns-adjustable($grid-gutter-width / 2);
}

.row.row-grid-lg {
    @include make-row($grid-gutter-width * 2);
    @include make-grid-columns-adjustable($grid-gutter-width * 2);
}

////////// Extra large grid //////////

@media (min-width: $screen-xl-min) {
  @include make-grid(xl);
}
