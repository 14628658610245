@font-face {
    font-family: 'PCextreme';
    src:url('/fonts/pcextreme/pcextreme.eot?ho9ywo');
    src:url('/fonts/pcextreme/pcextreme.eot?#iefixho9ywo') format('embedded-opentype'),
        url('/fonts/pcextreme/pcextreme.woff?ho9ywo') format('woff'),
        url('/fonts/pcextreme/pcextreme.ttf?ho9ywo') format('truetype'),
        url('/fonts/pcextreme/pcextreme.svg?ho9ywo#pcx') format('svg');
    font-weight: normal;
    font-style: normal;
}

.pcx {
    font-family: 'PCextreme';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pcx-core1:before {
    content: "\e604";
}

.pcx-core2:before {
    content: "\e605";
}

.pcx-core4:before {
    content: "\e606";
}

.pcx-core6:before {
    content: "\e607";
}

.pcx-compute:before {
    content: "\e601";
}

.pcx-hdd:before {
    content: "\e602";
}

.pcx-ssd:before {
    content: "\e603";
}

.pcx-infinity:before {
    content: "\e600";
}
