//////////////////////////////
// Forms
//////////////////////////////

@import "bootstrap/forms";

// Overrides
// @import "forms/forms-generic";

// Modules
@import "forms/forms-checkbox";

label {
    font-weight: normal;
}

.form-control {
    @include box-shadow(none);

    &:focus {
        border-color: $input-border-focus;
    }
}
