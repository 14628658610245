////////////////////////////////////////
// Core variables and mixins
////////////////////////////////////////

@import "variables";
@import "pcextreme/foundation/resources/sass/foundation";

////////////////////////////////////////
// Fonts
////////////////////////////////////////

@import "fonts/pcextreme";

////////////////////////////////////////
// Components
////////////////////////////////////////

@import "partials/layout";
@import "partials/content";
@import "partials/panels";

@import "partials/ace";
@import "partials/console";

@import "partials/rickshaw";
@import "partials/graph";

@import "partials/loader";
@import "partials/labels";

@import "partials/country-flags";

@import "partials/buttons";
@import "partials/popover-price";
@import "partials/panel-offering";

@import "partials/status";

@import "partials/quickfix";

////////////////////////////////////////
// Variations
////////////////////////////////////////

@import "partials/variants";
@import "partials/actionbar-variants";

// GLOBAL PATCHES
#trengo-web-widget * {bottom: 10px;}
#trengo-web-widget .LetsDoItLikeThis__iframe {bottom: 88px;}
