//////////////////////////////
// Component animations
//////////////////////////////

@import "bootstrap/component-animations";

.collapsing {
    @include transition-duration(.25s);
    @include transition-timing-function(ease);
}
