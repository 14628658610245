//////////////////////////////
// Pagination
//////////////////////////////

@import "bootstrap/pagination";

.pagination {
    > li {
        > a,
        > span {
            margin: 0 5px;
            min-width: 33px; // Makes the circle square, line-height (14*1.5) + padding (10) + border (2)
            border-radius: 16px;
        }
        &:first-child,
        &:last-child {
            > a,
            > span {
                border-radius: 16px;
            }
        }
    }
}

.panel-footer .pagination,
.modal-footer .pagination {
    margin: 10px 0;
    vertical-align: top;
}
