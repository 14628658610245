//////////////////////////////
// NavBar Toggle
//
// "Wednesday we wear pink."
// @see https://www.youtube.com/watch?v=xBbOAVSBvpE
//////////////////////////////

.navbar-wednesday {
    background-color: $navbar-wednesday-bg;
    border-color: $navbar-wednesday-border;

    .navbar-brand {
        color: $navbar-wednesday-brand-color;
        &:hover,
        &:focus {
            color: $navbar-wednesday-brand-hover-color;
            background-color: $navbar-wednesday-brand-hover-bg;
        }
    }

    .navbar-text {
        color: $navbar-wednesday-color;
    }

    .navbar-nav {
        > li > a {
            color: $navbar-wednesday-link-color;

            &:hover,
            &:focus {
                color: $navbar-wednesday-link-hover-color;
                background-color: $navbar-wednesday-link-hover-bg;
            }
        }
        > .active > a {
            &,
            &:hover,
            &:focus {
                color: $navbar-wednesday-link-active-color;
                background-color: $navbar-wednesday-link-active-bg;
            }
        }
        > .disabled > a {
            &,
            &:hover,
            &:focus {
                color: $navbar-wednesday-link-disabled-color;
                background-color: $navbar-wednesday-link-disabled-bg;
            }
        }
    }

    // Darken the responsive nav toggle
    .navbar-toggle {
        border-color: $navbar-wednesday-toggle-border-color;
        &:hover,
        &:focus {
            background-color: $navbar-wednesday-toggle-hover-bg;
        }
        .icon-bar {
            background-color: $navbar-wednesday-toggle-icon-bar-bg;
        }
    }

    .navbar-collapse,
    .navbar-form {
        border-color: darken($navbar-wednesday-bg, 7%);
    }

    // Dropdowns
    .navbar-nav {
        > .open > a {
            &,
            &:hover,
            &:focus {
                background-color: $navbar-wednesday-link-active-bg;
                color: $navbar-wednesday-link-active-color;
            }
        }

        @media (max-width: $grid-float-breakpoint-max) {
            // Dropdowns get custom display
            .open .dropdown-menu {
                > .dropdown-header {
                    border-color: $navbar-wednesday-border;
                }
                .divider {
                    background-color: $navbar-wednesday-border;
                }
                > li > a {
                    color: $navbar-wednesday-link-color;
                    &:hover,
                    &:focus {
                        color: $navbar-wednesday-link-hover-color;
                        background-color: $navbar-wednesday-link-hover-bg;
                    }
                }
                > .active > a {
                    &,
                    &:hover,
                    &:focus {
                        color: $navbar-wednesday-link-active-color;
                        background-color: $navbar-wednesday-link-active-bg;
                    }
                }
                > .disabled > a {
                    &,
                    &:hover,
                    &:focus {
                        color: $navbar-wednesday-link-disabled-color;
                        background-color: $navbar-wednesday-link-disabled-bg;
                    }
                }
            }
        }
    }

    .navbar-link {
        color: $navbar-wednesday-link-color;
        &:hover {
            color: $navbar-wednesday-link-hover-color;
        }
    }

    .btn-link {
        color: $navbar-wednesday-link-color;
        &:hover,
        &:focus {
            color: $navbar-wednesday-link-hover-color;
        }
        &[disabled],
        fieldset[disabled] & {
            &:hover,
            &:focus {
                color: $navbar-wednesday-link-disabled-color;
            }
        }
    }
}
