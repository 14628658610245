//////////////////////////////
// Actionbar
//////////////////////////////

@mixin actionbar-variant($nav-container-bg, $nav-link-color, $nav-link-hover-bg, $nav-active-link-hover-color, $nav-active-link-hover-bg) {
    .actionbar {
        background-color: $nav-container-bg;
    }

    .btn-actionbar {
        @include button-variant($nav-link-color, $nav-active-link-hover-bg, $nav-active-link-hover-bg);
    }

    .nav-actionbar {
        > li {
            float: left;

            > a + li {
                margin-left: 2px;
            }

            > a,
            > a:hover,
            > a:focus {
                color: $nav-link-color;
            }

            > a {
                &:hover,
                &:focus {
                    background-color: $nav-link-hover-bg;
                }
            }

            &.active > a {
                &,
                &:hover,
                &:focus {
                    color: $nav-active-link-hover-color;
                    background-color: $nav-active-link-hover-bg;
                }
            }
        }
    }
}
