//////////////////////////////
// Progress bars
//////////////////////////////

@import "bootstrap/progress-bars";

.progress {
    height: 6px;
    @include box-shadow(none);
}

.progress-inline {
    margin: 5px 0;
}
