.status {
    color: $brand-default;

    font-size: 75%;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .05em;

    &::before {
        content: '';
        width: 1rem; height: 1rem;
        margin-right: 1rem;

        border: .25rem solid rgba(255,255,255,0.5);

        background-color: $brand-default;
        vertical-align: middle;

        display: inline-block;
        border-radius: 9999px;
    }
}

// Pulse animation

.status-pulse {
    &::before {
        animation: statusPulse .5s infinite;
    }
}

@keyframes statusPulse {
  0% {
      border-width: .25rem;
      opacity: 1;
  }
  50% {
      border-width: 0rem;
      opacity: .5;
  }
  100% {
      border-width: 0rem;
      opacity: 1;
  }
}

// Variants

.status-success {
    color: $brand-success;

    &::before {
        background-color: $brand-success;
    }
}

.status-info {
    color: $brand-info;

    &::before {
        background-color: $brand-info;
    }
}

.status-warning {
    color: $brand-warning;

    &::before {
        background-color: $brand-warning;
    }
}

.status-danger {
    color: $brand-danger;

    &::before {
        background-color: $brand-danger;
    }
}
