//////////////////////////////
// ACE Editor
//////////////////////////////

.ace_editor {
    height: 200px;
    
}

div[ui-ace].ng-invalid.ng-dirty {
    .ace_gutter {
        background: $red-200;
        border-right: 2px solid $red-500;
        .ace_gutter-active-line {
            background: $red-300;
        }
    }
    .ace_content {
        background: $red-100;
    }
}