//////////////////////////////
// Close
//////////////////////////////

@import "bootstrap/close";

.close {
    opacity: 1;

    &:hover,
    &:focus {
        opacity: 1;
    }
}
