//////////////////////////////
// Panels
//////////////////////////////

@import "bootstrap/mixins/panels";

@mixin panel-image-variant($background-color, $color) {
    background-color: $background-color;
    color: $color;
}
