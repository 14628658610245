.label-audit {
    min-width: 100px;
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
}

.label-linked {
    width: 40px; height: 40px;
    margin: 0 auto; padding: 0;
    margin-bottom: 20px;

    font-size: inherit;

    display: block;
    position: relative;
    border-radius: 20px;

    &:before,
    &:after {
        content: "";
        width: 2px; height: 20px;
        margin-top: -20px;
        margin-left: -1px;
        background-color: $grey-400;
        position: absolute;
        top: 0px;
        left: 50%;
    }

    &:after {
        margin-top: 0;
        margin-bottom: -20px;

        top: auto;
        bottom: 0px;
    }

    i {
        line-height: 40px;
    }
}
