//////////////////////////////
// Callouts
//
// Based on Bootstrap documentation callouts.
// @see https://github.com/twbs/bootstrap/blob/v4-dev/site/_includes/callout.html
//////////////////////////////

.callout {
    margin: 0; padding: 10px 20px;
    margin-bottom: 20px;
    border-left: 2px solid;

    > h4 {
        font-size: $font-size-base;
        margin-top: 0;
        margin-bottom: 5px;
    }

    > p:last-child {
        margin-bottom: 0;
    }

    > code {
        background-color: #fff;
        border-radius: 3px;
    }
}

// Variants

.callout-default {
    @include callout-variant($grey-300, transparent, $grey-500, $text-color);
}

.callout-success {
    @include callout-variant($green-500, transparent, $green-500, $text-color);
}

.callout-info {
    @include callout-variant($light-blue-500, transparent, $light-blue-500, $text-color);
}

.callout-warning {
    @include callout-variant($amber-500, transparent, $amber-500, $text-color);
}

.callout-danger {
    @include callout-variant($red-500, transparent, $red-500, $text-color);
}
