.breadcrumb > li + li:before {
    font-family: 'Font Awesome 5 Pro';
}

.sidebar .panel-heading > .panel-title::before,
.sidebar .panel-body .nav-pills > li > a::before {
    display: none;
}

.list-heading h1::before,
.list-heading h2::before,
.list-heading h3::before,
.list-heading h4::before,
.list-heading h5::before,
.list-heading h6::before,
.list-heading .h1::before,
.list-heading .h2::before,
.list-heading .h3::before,
.list-heading .h4::before,
.list-heading .h5::before,
.list-heading .h6::before {
    display: none;
}

a.list-group-item:disabled,
button.list-group-item:disabled {
    color: $btn-link-disabled-color;
    cursor: not-allowed;
}
