//////////////////////////////
// Sidebar Layout
//////////////////////////////

body {
    overflow-x: hidden;
}

div#wrapper {
    height: 100%;
    padding-left: 0;

    -webkit-transition: all 0.5s ease;
       -moz-transition: all 0.5s ease;
         -o-transition: all 0.5s ease;
            transition: all 0.5s ease;
}

div#content, div#sidebar-wrapper {
    padding-top: $navbar-height;
    top: 0;
    bottom: 0;
}

div#content {
    width: 100%; height: 100%;
    position: relative;
}

div#sidebar-wrapper {
    width: 0; height: auto;
    margin-left: -250px;

    overflow: hidden;
    overflow-y: auto;

    position: fixed;
    left: 250px;

    z-index: 1000;

    -webkit-transition: all 0.3s ease;
       -moz-transition: all 0.3s ease;
         -o-transition: all 0.3s ease;
            transition: all 0.3s ease;
}

div#wrapper {
    div#content {
        width: auto;

        position: absolute;
        left: 0; right: 0;

        -webkit-transition: all 0.3s ease;
           -moz-transition: all 0.3s ease;
             -o-transition: all 0.3s ease;
                transition: all 0.3s ease;
    }
}

div#wrapper.in {
    div#content {
        left: 250px; right: -250px;
    }

    div#sidebar-wrapper {
        width: 250px;
    }
}

@media(min-width: $grid-float-breakpoint) {
    div#wrapper {
        div#content {
            left: 250px;
        }

        div#sidebar-wrapper {
            width: 250px;
        }
    }

    div#wrapper.in {
        div#content {
            left: 250px; right: 0;
        }
    }
}
