.popover-price {
    width: 300px;
    border-radius: 3px;

    .popover-title {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 20px;
        color: #222;
    }

    .popover-content {
        padding: 10px 20px;
        font-size: 14px;

        .prices {
            padding: 0;
            margin-bottom: 0;
            .item {
                list-style-type: none;
                margin-bottom: 10px;
                text-transform: capitalize;

                &.item-total {
                    font-weight: bold;
                    border-top: 1px solid $grey-100;
                    margin: 10px -20px 0 -20px;
                    padding: 10px 20px 0 20px;
                }
            }
        }
    }
}
