//////////////////////////////
// Type
//////////////////////////////

@import "bootstrap/type";

////////// Emphasis variants //////////

@include text-emphasis-variant('.text-normal', $text-color);
@include text-emphasis-variant('.text-white', $white);

////////// Weight variants //////////
//
// Based on CSS standards.
// @see https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight
//

.weight-normal { font-weight: normal; }
.weight-bold { font-weight: bold; }

// Keyword values relative to the parent
.weight-lighter { font-weight: lighter; }
.weight-bolder { font-weight: bolder; }

////////// Text variants //////////

.text-strike { text-decoration: line-through; }

////////// Headings //////////

// We use a custom class instead of the targeting the headings directly,
// this conflicted with various other headings in our pages.
.page-title {
    // Adds an invisible ::before element making the heading 'taller'.
    // This prevents the browser from 'headbutting' the heading when hash routing it.
    // @see https://css-tricks.com/hash-tag-links-padding/
    &::before {
        content: " ";
        height: $navbar-height+$spacer-y;
        margin-top: -($navbar-height+$spacer-y);

        display: block;
        visibility: hidden;
        pointer-events: none;
    }
}

.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $font-family-sans-serif;
}

////////// Contextual backgrounds //////////

@include bg-variant('.bg-default', $state-default-bg);

////////// Icon intro //////////

.intro-title {
    font-size: ceil(($font-size-base * 1.125));
}

.intro-icon {
    font-size: 54px;
}

////////// Description lists //////////

.dl-heading {
    margin: 0;

    font-size: $font-size-small;
    font-family: $font-family-sans-serif;
    font-weight: bold;
    line-height: 40px;

    color: $light-blue-500;
}

dl {
    margin-top: 0; // Remove browser default
    margin-bottom: $line-height-computed;

    > a {
        &,
        &:hover,
        &:focus,
        &:active {
            color: inherit;
            text-decoration: none;
        }

        &:hover,
        &:focus,
        &:active {
            dt,
            dd {
                margin-left:  -$panel-body-padding;
                margin-right: -$panel-body-padding;
                padding-left:  $panel-body-padding;
                padding-right: $panel-body-padding;

                background-color: $grey-100;
            }
        }
    }
}

dt,
dd {
    line-height: 20px;

    small {
        font-size: $font-size-small;
        color: $grey-500;
    }
}
dt {
    padding-top: 10px;
    font-weight: normal;
}
dd {
    margin-left: 0; // Undo browser default
    padding-bottom: 10px;

    font-size: $font-size-small;
    color: $grey-500;

    border-bottom: 1px solid $grey-200;

    &:last-child {
        border-bottom: none;
    }
}

////////// Horizontal description lists //////////

.dl-horizontal {
    dd {
        @include clearfix; // Clear the floated `dt` if an empty `dd` is present
    }

    @media (min-width: $grid-float-breakpoint) {
        dt, dd {
            height: 60px;
            line-height: 60px;

            border-bottom: 1px solid $grey-200;
        }

        dt {
            width: ($dl-horizontal-offset);
            padding-top: 0;

            text-align: left;

            float: left;
            clear: left;
            @include text-overflow;
        }
        dd {
            margin-left: $dl-horizontal-offset;
            padding-left: 20px;
            padding-bottom: 0;

            font-size: $font-size-base;
        }
    }
}

////////// Text //////////

.link-more {
    color: $link-color;
    position: relative;
    display: inline-block;

    &:hover {
        color: $link-hover-color;
        text-decoration: none;

        &:after {
            background: $link-hover-color;
        }
    }

    &:after {
        display: block;
        height: 1px;
        background: $link-color;
        content: "";
    }
}

////////// No Wrap //////////

.nowrap {
    white-space: nowrap;
}

.wrap-break-word {
    word-wrap: break-word;
}

////////// Price amount //////////

.amount {
    white-space: nowrap;

    .data-type {
        margin-top: 1px;
        border-bottom: 1px solid;

        font-size: 10px;
        font-weight: bold;
        line-height: 13px;
        vertical-align: top;

        display: inline-block;
    }
}
