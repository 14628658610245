.content {
    height: 100%; min-height: 100%;
    display: block;
}

////////// Alignment //////////
@media (min-width: $grid-float-breakpoint) {
    .content.content-sidebar-left.content-sidebar {
        margin-left: 250px;
    }

    .content.content-sidebar-left.content-sidebar-sm {
        margin-left: 60px;
    }

    .content.content-sidebar-left.content-sidebar-lg {
        margin-left: 500px;
    }

    .content.content-sidebar-right.content-sidebar {
        margin-right: 250px;
    }

    .content.content-sidebar-right.content-sidebar-sm {
        margin-right: 60px;
    }

    .content.content-sidebar-right.content-sidebar-lg {
        margin-right: 500px;
    }
}

////////// Padded //////////

.content-padded {
    padding-top: 20px;
}

////////// Navigation bar //////////

.content-navbar {
    padding-top: $navbar-height;
}

.content-navbar.content-padded {
    padding-top: 80px;
}
