//////////////////////////////
// Title bar
//////////////////////////////

@mixin titlebar-variant($primary-color, $secondary-color, $highlight-color) {
    .titlebar {
        background-color: $primary-color;

        .stats {
            >  .toggle {
                color: $highlight-color;
            }
        }

        .stats-title {
            color: $highlight-color;
        }

        .stats-count {
            > small {
                color: $highlight-color;
            }
        }
    }

    .titlebar-heading {
        .breadcrumb {
            margin: 0;

            > li {
                + li:before {
                    color: $secondary-color;
                }

                > a,
                > span {
                    color: $highlight-color;
                }
            }
        }
    }

    .titlebar-footer {
        border-top-color: $secondary-color;

        > .row {
            > div {
                border-right-color: $secondary-color;
            }
        }
    }
}
