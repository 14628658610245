//////////////////////////////
// Input groups
//////////////////////////////

@import "bootstrap/input-groups";

// Overrides
// @import "input-groups/input-groups-generic";

// Reset margin on labels when used as input group addon.
.input-group-addon label {
    margin-bottom: 0;
}

// Style input group buttons.
.input-group-btn .btn {
    // background-color: $input-bg;
    // border-color: $input-border;
    line-height: 2;

    @include box-shadow(none);

    &.disabled,
    &[disabled] {
        cursor: $cursor-disabled;
        color: $input-color-placeholder;

        @include opacity(1);
        @include box-shadow(none);

        pointer-events: none;
    }
}

.input-group-btn .btn-default {
    background-color: $input-bg;
    border-color: $input-border;
}

// Style input addon label

.input-group-addon label {
    margin: (-$padding-base-vertical - 1) (-$padding-base-horizontal - 2);
    padding: $padding-base-vertical $padding-base-horizontal;

    border: 1px solid $input-border;
    border-radius: $input-border-radius;
    background-color: $input-bg;
    line-height: 2;
    vertical-align: middle;
    display: table;
    position: relative;
    cursor: pointer;

    @include transition(border-color ease-in-out .15s);

    > .fa, > .far, > .fal, > .fas, > .fab {
        @include transition(color ease-in-out .15s);
    }

    &:hover {
        > .fa, > .far, > .fal, > .fas, > .fab {
            color: $input-border-focus;
        }
    }
}

.input-group-addon:first-child label {
    @include border-right-radius(0);
    border-right-width: 0;
    margin-right: -($padding-base-horizontal + 2);
}

.input-group-addon:last-child label {
    @include border-left-radius(0);
    border-left-width: 0;
    margin-left: -($padding-base-horizontal + 2);
}

// Reset z-index on form controls and input button addons
.input-group .form-control,
.input-group .form-control:focus {
    z-index: 1;
}

.input-group-addon label,
.input-group-btn .btn {
    @include transition(border-color ease-in-out .15s);
    z-index: 2;
}

// Input control overlap for buttons.
.input-group-btn:first-child > .btn {
    margin-right: -2px;
    border-right-width: 0px;
}

.input-group-btn:last-child > .btn {
    margin-left: -2px;
    border-left-width: 0px;
}

// Input group states.
.form-group,
.checkbox,
.input-group {
    position: relative;

    &.focus {
        .input-group-addon label,
        .input-group-btn .btn {
            border-color: $input-border-focus;
        }

        .input-group-addon label {
            > .fa, > .far, > .fal, > .fas, > .fab {
                color: $input-border-focus;
            }
        }
    }

    .input-icon { display: none; }

    &.processing {
        .input-icon {
            display: block;
            position: absolute;
            color: $input-border-focus;
            top: 15px;
            right: 8px;
            font-size: 14px;
        }
        &.required .input-icon { right: 20px; }
    }


    &.required {
        &:before {
            @include fa-preset();

            content: "\f111";
            font-size: 6px;
            font-weight: bold;

            color: $input-border-focus;
            position: absolute;
            top: ($input-height-base / 2) - 3; // - font-size/2 + border size
            right: 10px;
            z-index: 2;
        }
    }

    &.select {
        &.required:before {
            right: 30px;
        }

        &:after {
            @include fa-icon();
            content: "\f0dc";

            font-size: 14px;

            font-family: 'Font Awesome 5 Pro';
            font-style: normal;
            font-weight: 900;

            color: $input-color;
            position: absolute;
            top: ($input-height-base / 2) - 8; // - font-size/2 + border size
            right: 10px;
            z-index: 2;
        }
    }

    select {
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
    }
}

// Float label
.label-float, .float > .validation {
    font-size: 11px;
    opacity: 0;

    display: block;
    position: absolute;
    top: 6px; left: $padding-base-horizontal;

    user-select: none;
    z-index: 1; // Position the label above .input-group elements.

    -webkit-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
}

.input-group + .label-float,
.input-group + .label-float + .label-float {
    left: $input-group-addon-float-min-width + 12px;
    z-index: 1;
}

// The validation message will be applied to a second label floating above the
// main label. By making the background white we can 'hide' the main label.
.validation {
    width: 80%;
    background: $white;
}

.float {
    .input-group-addon {
        min-width: $input-group-addon-float-min-width;

        > label {
            // Including 2px border overlap.
            min-width: $input-group-addon-float-min-width + 2px;
        }
    }

    .form-control {
        // Extend default transitions
        @include transition(padding .2s ease-in-out, border-color ease-in-out .15s, box-shadow ease-in-out .15s);
    }
}

.form-group,
.input-group {
    &.float.in,
    &.float.has-error {
        .form-control {
            padding: ($padding-base-vertical + 6) $padding-base-horizontal ($padding-base-vertical - 6);
        }

        textarea.form-control {
            padding: ($padding-base-vertical + 12) $padding-base-horizontal ($padding-base-vertical + 6);
        }

        .label-float {
            opacity: 1;
        }
    }
}

.form-group.has-error {
    &.float {
        .label-float {
            z-index: 1;
        }

        .validation {
            opacity: 1;
            z-index: 2;
        }
    }
}

// Input group addon
.form-group.focus,
.input-group.focus {
    .input-group-addon label {
        border-color: $input-border-focus;
    }
}

.form-group.has-error,
.input-group.has-error {
    .input-group-addon label {
        border-color: $state-danger-border;
    }
}
