.loader-bar {
    width: 100%;
    height: 4px;

    overflow: hidden;

    position: absolute;
    top: 0;
}

.loader-bar > div {
    position: absolute;
    height: 100%;
    left: 50%;
}

.loader-bar .light-blue {
    background: #5bc0de;
}

.loader-bar .dark-blue {
    background: #2980b9;
}

.sd0 {
    -webkit-animation: stretch 2.8s ease 0s infinite;
    -moz-animation: stretch 2.8s ease 0s infinite;
    -ms-animation: stretch 2.8s ease 0s infinite;
    -o-animation: stretch 2.8s ease 0s infinite;
    animation: stretch 2.8s ease 0s infinite;
}

.sd05 {
    -webkit-animation: stretch 2.8s ease .4s infinite;
    -moz-animation: stretch 2.8s ease .4s infinite;
    -ms-animation: stretch 2.8s ease .4s infinite;
    -o-animation: stretch 2.8s ease .4s infinite;
    animation: stretch 2.8s ease .4s infinite;
}

.sd1 {
    -webkit-animation: stretch 2.8s ease .8s infinite;
    -moz-animation: stretch 2.8s ease .8s infinite;
    -ms-animation: stretch 2.8s ease .8s infinite;
    -o-animation: stretch 2.8s ease .8s infinite;
    animation: stretch 2.8s ease .8s infinite;
}

.sd15 {
    -webkit-animation: stretch 2.8s ease 1.2s infinite;
    -moz-animation: stretch 2.8s ease 1.2s infinite;
    -ms-animation: stretch 2.8s ease 1.2s infinite;
    -o-animation: stretch 2.8s ease 1.2s infinite;
    animation: stretch 2.8s ease 1.2s infinite;
}
