.panel-offering {
    margin-bottom: 0;
    background: $white;

    border: 2px solid $grey-200;
    border-left-width: 1px;
    border-right-width: 1px;

    border-radius: 0;
    @include box-shadow(none);

    transition: box-shadow .5s ease;

    &:focus {
        outline: none
    }

    &:first-child {
        border-left-width: 2px;
    }

    &:last-child {
        border-right-width: 2px;
    }

    &.active {
        margin: -10px -1px;
        // In compute offerings are jumpy when this is active padding: 10px 0;

        border-left-width: 2px;
        border-right-width: 2px;

        border-color: $light-blue-500;
        @include box-shadow(0 0 20px $light-blue-500);

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.panel-offering .dl-horizontal {
    dd {
        @include clearfix; // Clear the floated `dt` if an empty `dd` is present
    }

    @media (min-width: $dl-horizontal-breakpoint) {
        dt, dd {
            height: auto;
            line-height: 2;
            border-bottom: none;
        }

        dt {
            padding-right: 5px;
            float: left;
            width: 50%;
            clear: left;
            font-weight: bold;
            text-align: right;
            @include text-overflow;
        }
        dd {
            padding-left: 5px;
            margin-left: 50%;
            text-align: left;
        }
    }
}
