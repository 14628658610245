//////////////////////////////
// Sidebar
//////////////////////////////

.sidebar {
    background-color: $sidebar-default-bg;
    border-right: 1px solid $sidebar-default-border;

    .dropdown-menu {
        width: 100%;
    }
}

.sidebar-content {
    width: $sidebar-width;
    padding: 20px 0;
}

////////// Heading //////////

.sidebar-heading {
    padding: 0 20px;
    margin-bottom: 5px;

    font-size: $font-size-extra-small;
    font-weight: 500;
    text-transform: uppercase;

    color: $text-muted;
    display: block;
}

////////// HR //////////

.sidebar hr {
    margin: 0 20px;
    border-style: dashed;
    border-color: $sidebar-default-border;
}

////////// Button group //////////

.sidebar .btn-group {
    padding: 0 20px;
}

////////// Navigation //////////

.sidebar-nav {
    .nav-stacked > li + li {
        margin-top: 5px;
    }
}

.sidebar .nav-pills > li {
    position: relative;

    > a {
        padding: 4px 20px;
        padding-left: 50px;

        background-color: transparent;

        line-height: 32px;
        color: $text-color;

        border-radius: 0;

        &:hover,
        &:focus,
        &:active {
            background-color: $grey-200;
            color: $light-blue-500;
        }

        &:focus {
            // ...
        }

        &:active {
            color: $light-blue-500;
        }

        .fa-fw {
            width: 20px;
            margin-left: -30px;
            margin-right: 10px;

            font-size: 14px;
            line-height: 32px;

            float: left;
        }
    }

    &.active {
        > a:first-child {
            font-weight: bold;
            color: $light-blue-500;

            background-color: transparent;

            &:hover {
                background-color: $grey-200;
            }

            &:focus {}

            &:active {}
        }
    }
}

////////// Stacked navigation //////////

.sidebar .nav-stacked {
    margin-bottom: 20px;
}

////////// Panel group //////////

.sidebar .panel-group {
    margin: 0;
    margin-bottom: 5px;
}

.sidebar .panel-group .panel {
    border-radius: 0;
}

.sidebar .panel-group .panel + .panel {
    margin-top: 5px;
    border-top: none;
}

////////// Panel //////////

.sidebar .panel {
    border: none;
    background-color: transparent;

    -webkit-box-shadow: none;
       -moz-box-shadow: none;
            box-shadow: none;

    &.panel-open {
        background-color: $sidebar-default-contrast;
    }
}

////////// Panel heading //////////

.sidebar .panel-heading {
    margin: 0; padding: 0;
    color: inherit;

    position: relative;

    > .panel-title {
        font-family: $font-family-sans-serif;
    }

    > .panel-title > a {
        padding: 4px 20px;
        padding-left: 50px;

        font-size: $font-size-base;
        font-weight: normal;
        line-height: 32px;
        color: inherit;
        text-decoration: none;
        text-transform: none;

        background-color: transparent;
        color: $text-color;

        display: block;
        position: relative;

        border-radius: 0;

        &:hover,
        &:focus,
        &:active {
            outline: none;
        }

        &:hover {
            background-color: $grey-200;
        }

        &:focus {}

        &:active {}

        .fa-fw {
            width: 20px;
            margin-left: -30px;
            margin-right: 10px;

            font-size: 14px;
            line-height: 32px;

            float: left;
        }
    }
}

.sidebar .panel {
    &.active {
        .panel-heading > .panel-title > a {
            background-color: $white;

            font-weight: bold;
            color: $light-blue-500;

            &:hover {
                background-color: $grey-200;
            }

            &:focus {}

            &:active {}
        }
    }
}

////////// Nav pills //////////

// Add a little grey line to indicate 'sub-items'.
.sidebar .nav-pills > li > a::before {
    content: "";
    width: 2px; height: 100%;

    background-color: $grey-300;

    display: block;
    position: absolute;
    top: 0;
    left: 29px;
}

// The first element of a sidebar nav-pill mostly contains an icon.
// Adding the 'sub-item' indicator would be a bit confusing.
.sidebar .nav-pills > li > a:first-child::before {
    display: none;
}

////////// Appended button //////////

.sidebar .panel-heading > .panel-title .collapse-toggle,
.sidebar .nav-pills > li > a.nav-action {
    width: 40px; height: 40px;
    margin: 0; padding: 0;

    border: none;
    color: $text-muted;

    line-height: 40px;
    text-decoration: none;
    text-align: center;

    display: block;
    z-index: 1;

    position: absolute;
    top: 0; right: 0;
}

.sidebar .nav-pills > li > a.nav-action {
    width: 33px; height: 33px;
    line-height: 33px;
}


////////// Collapse toggle //////////

.sidebar .panel-heading > .panel-title .collapse-toggle {
    border: none;

    font-size: $font-size-base;
    font-weight: normal;
    text-transform: none;

    color: $text-muted;
}

////////// Panel body //////////

.sidebar .panel-group .panel-heading + .panel-collapse > .panel-body {
    margin: 0; padding: 10px 0;

    background-color: $sidebar-default-contrast;
    border: 1px solid $sidebar-default-border;

    border-radius: none;
}

////////// Panel body pills //////////

.sidebar .panel-body > .nav-pills {
    margin-top: 0;
    margin-bottom: 0;

    > li > a:first-child {
        padding-top: 7px;
        padding-bottom: 7px;

        font-size: $font-size-small;
        line-height: 20px;
    }
}
