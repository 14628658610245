//////////////////////////////
// Layout
//////////////////////////////

html, body {
    height: 100%; min-height: 100%;
}

// Helper to add navbar padding to an element.
.has-navbar {
    padding-top: $navbar-height;
}
