$consoleWidth: 200px;
$consoleHeight: 200px;

.console-overflow {
    overflow: scroll;
}

.console-wrapper {
    width: $consoleWidth; height: $consoleHeight;
    margin: 0 auto;

    border: 1px solid $table-border-color;
    background-color: $white;

    display: block;
    position: relative;
    overflow: hidden;

    transition: width 1s, height 1s;

    .btn-toolbar {
        opacity: 0.8;
        position: absolute;
        top: 10px; right: 10px;
    }

    iframe {
        width: $consoleWidth; height: $consoleHeight + 32px;
        margin-top: -32px;
        border: none;
    }
}

.console-wrapper.toolbar {
    .btn-toolbar {
        top: 42px;
    }

    iframe {
        margin-top: 0;
    }
}
