//////////////////////////////
// Graphs
//////////////////////////////

.graph {
    margin-top: -$line-height-computed;
    margin-bottom: $line-height-computed;
    background-color: $blue-grey-800;
}

.graph-heading {
    padding: 15px 0;
}

.graph-body {
    position: relative;

    .spinner {
        margin-top: -($loading-spinner-height/2);
        margin-left: -($loading-spinner-width/2);

        position: absolute;
        top: 50%; left: 50%;
    }

    .graph-error {
        width: 100%;
        margin-top: -($line-height-computed/2);

        text-align: center;
        color: $blue-grey-200;

        display: block;
        position: absolute;
        top: 50%;
    }
}

////////// Dropdown //////////

.graph .dropdown > button {
    white-space: normal;
}

.graph .dropdown-menu {
    width: 100%;
}

////////// Renderers //////////

.rickshaw_graph {
    border-radius: 0 0 3px 3px;
    overflow: hidden;
}

// Renderer: Area

.rickshaw_graph g.primary path {
    fill: $light-blue-500;
}

.rickshaw_graph g.secondary path {
    fill: $light-blue-800;
}

// Renderer: Line

.rickshaw_graph path.primary {
    stroke: $light-blue-500;
}

.rickshaw_graph path.secondary {
    stroke: $light-blue-800;
}

////////// Grid lines / labels //////////

// X - ticks

.rickshaw_graph .x_tick {
    width: 1px;
    border: none;

    background-color: $black;
    background-color: rgba(0, 0, 0, 0.1);
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
}

.rickshaw_graph .x_tick .title {
    font-family: inherit;
    font-size: 12px;
    color: $text-color;
    opacity: 1;
}

// Y - ticks

.rickshaw_graph .y_ticks text {
    font-family: inherit;
    font-size: 12px;
    fill: $text-color;
    opacity: 1;

    baseline-shift: -10px;
}

.rickshaw_graph .y_ticks path {
    stroke: none;
}

.rickshaw_graph .y_ticks .tick line {
    stroke: none;
}

// Grid

.rickshaw_graph .y_grid .tick {
    stroke: rgba(0, 0, 0, 0.1);
    stroke-dasharray: 0;
}

////////// Details //////////

.rickshaw_graph .detail {
    background-color: $blue-grey-900;
    visibility: hidden;

    .dot {
        width: 6px; height: 6px;
        margin-top: -3px;
        margin-left: -3px;

        border-width: 0;
        border-radius: 3px;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
    }

    .item {
        line-height: 1.4;
        padding: 0.5em
    }

    .date {
        color: $text-color;
    }
}

.rickshaw_graph:hover .detail {
    visibility: visible;
}

.rickshaw_graph .detail .x_label {
    border: none;
    background: $blue-grey-900;

    font-family: $font-family-sans-serif;
    color: $white;

    bottom: 0;
    border-radius: $border-radius-base;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    opacity: 1;
}

.rickshaw_graph .detail .x_label.left {
    border-top-left-radius: 0;
}

.rickshaw_graph .detail .x_label.right {
    border-top-right-radius: 0;
}

////////// Detail swatch //////////

.detail_swatch {
    width: 10px; height: 10px;
    margin: 0;

    display: inline-block;
    float: right;

    border-radius: 5px;
}
